import orders from './columns/orders';
import jobs from './columns/jobs';
import titles from './columns/titles';
import tasks from './columns/tasks';
import processingTasks from './columns/processingTasks';
import pendingAssets from './columns/pendingAssets';
import theme from './theme';
import jobDetails from './jobDetails';
import viewingTitle from './viewingTitle';
import dataConfig from './dataConfig';
import dashboardConfig from './dashboardConfig';
import taskConfig from './taskConfig';
import titleConfig from './titleConfig';
import orderPlacementConfig from './orderPlacementConfig';
import { ClientConfig } from '@/__configs/config_types';
import { DeepPartial } from '@/redux/slices/slice_types';

const config: DeepPartial<ClientConfig> = {
  clientName: 'A+E',
  theme,
  viewingTitle,
  columns: { orders, jobs, processingTasks, tasks, titles, pendingAssets },
  jobDetails,
  dataConfig,
  taskConfig,
  titleConfig,
  dashboardConfig,
  orderPlacementConfig,
  ingestManagementConfig: {
    assetTypeValueMap: {
      Broadcast: 'BCM',
      International: 'IMF'
    },
    allowedUploadsBlurb: 'Select any MOV or MXF file(s) below.'
  },
  userColors: ['#aa6600', '#118833', '#6e2a9f', '#952a9f', '#cc4455', '#71a400', '#667799', '#bb44aa', '#8855ee', '#5566ee', '#4466ff', '#dd2277'],
  versionColors: {
    core: '#72A73C',
    nlm: '#EC5743',
    glb: '#B16BE9',
    bcm: '#5566ee'
  },
  bulkSearch: false,
  enableUserDocumentation: true,
  disableJobDetailProxy: false,
  commonConstants: {
    fileTypeGrouping: {
      video: ['mov', 'mxf', 'mp4', 'avi', 'mpg', 'mpeg'],
      audio: ['wav', 'aac', 'mp3'],
      subtitle: ['srt', 'scc', 'vtt', 'webvtt', 'sub', 'ass', 'ssa', 'ttml', 'dfxp', 'itt', 'stl'],
      image: ['jpg', 'jpeg', 'bmp', 'png', 'gif', 'svg', 'tiff', 'tif'],
      other: ['pdf', 'prproj', 'xml', 'csv', 'xls', 'xlsx', 'txt', 'doc', 'docx']
    }
  }
};

export default config;
